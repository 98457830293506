.ProductList{
  img{
    width: 50px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  button {
      border: none;
    border-radius: 12px;
    background-color: red;
    color: white;
  }
  .flex5 {
    display: flex;
    flex-direction: row;
  }
}

