.characteristick{
    display: flex;
    align-items: center !important;
    gap: 2px;
 p{
    font-weight: bold;
 }
 li{
   font-size: 16px !important;
 }
}
