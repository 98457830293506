
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .contact {
    height: 7vh;
    background: #000000;
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #969696;
  }
  .contact .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .line {
    outline: none;
    height: 10px;
    width: 2px;
    border-right: 2px solid #969696;
    margin: 0 15px;
  }
  .left__content {
    display: flex;
    align-items: center;
  }
  .contact__link {
    text-decoration: none;
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #969696;
    display: flex;
  }
  .contact__link img {
    margin-right: 5px;
  }
  .main {
    color: #ffffff;
    font-family: "PT Sans", sans-serif;
  }
  .container {
    width: 70%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
   .container{
    width: 100%;
   }
  }
  @media (max-width: 576px) {
    .container{
        width: 100%;
        justify-content: center !important;
        align-items: center;
       }
  }
  