@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }
}
.nav2{
    display: flex;
    justify-content: space-between;
    gap: 45px ;
  }
hr {
  width: 120px;
  height: 1px;
  background-color: #ffffff;
}
html,
body {
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar,
.box-item::-webkit-scrollbar,
.search-box::-webkit-scrollbar {
  width: 8px;
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body::-webkit-scrollbar-thumb,
.box-item::-webkit-scrollbar-thumb,
.search-box::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
}

.productItem:hover {
  transition: opacity 0.3s linear;
  opacity: 0.5;
}

.box-item {
  height: 320px;
  overflow-y: scroll;
}
.loader-box {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0;
}

.loader {
  position: relative;
  width: 70px;
  height: 70px;
}

.loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}

.loader span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  transform: scale(0);
  animation: animate 2s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.pagination-btn {
  margin: 5px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-btn.active {
  background-color: #ffffff;
  color: black;
}

.pagination-btn:hover {
  background-color: #0d0e0f;
  color: white;
}
.bm-menu {
  display: flex;
  flex-direction: column;
}
.bm-item-list {
  display: flex;
  flex-direction: column;
}
.bm-item {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  gap: 25px;
  padding-top: 50px;
}
.bm-item a {
  color: white;
}
.bm-burger-button button {
  height: 15% !important;
}  
@media (max-width: 768px) {
  .pagination-btn {
    padding: 6px 12px;
    margin: 3px;
  }
}

@media (max-width: 480px) {
  .pagination-btn {
    padding: 4px 8px;
    margin: 2px;
  }
}

@keyframes animate {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1.2);
  }
  80%,
  100% {
    transform: scale(0);
  }
}
