.delivery{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 20px;
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: center;
    .details, .order{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .details{
        padding-top: 30px;
    }
    h1{
        font-size: 46px;
        color: #ffffff;
        font-family: "Playfair Display",sans-serif ;

    }
    ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        li{
            color: #ffffff;
            font-size: 16px;
            width: 70%;
       
        }
    }
    p{
        color: #ffffff;
        font-size: 16px;
        width: 70%;
    }
}
@media (max-width: 576px){
    h1{
        font-size: 32px !important;
    }
    li{
        font-size: 12px !important;
        width: 100%;
    }

}