* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img{
  cursor: pointer;
}
footer {
  min-height: 50vh;
  background-image: url(../images/Rectangle11.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
}
footer .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.about__store {
  width: 30%;
}
.categories {
  width: 30%;
}
.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}
.aboutus__title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 80px;
  margin-bottom: 40px;
}
.aboutus__txt {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
}
.categories .choose {
  display: flex;
  flex-wrap: wrap;
}
.categories .choose a {
  text-decoration: none;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #d0d0d0;
  padding: 10px 20px;
  background-color: #0f0f0f;
  margin-right: 20px;
  margin-bottom: 20px;
}

input {
  padding: 10px 10px;
}

.enter {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  background: rgba(255, 255, 255, 0.4);
  color: #ffffff;
  border: none;
  outline: none;
}
input::placeholder {
  color: #d0d0d0;
}
button {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-transform: uppercase;
  background: #0f0f0f;
  border: none;
  outline: none;
  padding: 10px 18px;
  cursor: pointer;
}
.flex__content {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 1300px) {
  #season__slider {
    width: 530px;
    overflow: scroll;
    gap: 20px;
  }
  #season__slider::-webkit-scrollbar {
    width: 0;
  }
}
@media (max-width: 1100px) {
  #season__slider {
    width: 305px;
    gap: 10px;
  }
  header nav {
    width: 60%;
  }
}
@media (max-width: 912px) {
  header nav {
    width: 70%;
  }
  .bottom .right p {
    max-width: 400px;
  }
}
@media (max-width: 768px) {
  #season__slider {
    margin-bottom: 40px;
  }
  .contact .container {
    width: 90%;
    justify-content: space-between;
  }
  .burger__icon {
    display: block;
  }
  header nav {
    display: none;
  }
  header .container {
    width: 90%;
  }
  .top {
    height: 100%;
    flex-direction: column;
    padding-top: 20px;
  }
  .top .left {
    width: 100%;
  }
  .top .left hr {
    margin: 35px 0;
  }
  .top .right {
    height: 60vh;
    width: 100%;
  }
  .bottom {
    flex-direction: column;
    height: 98vh;
  }
  .bottom .left {
    width: 100%;
    height: 70vh;
    order: 1;
    background-position: center center;
  }
  .bottom .right {
    width: 100%;
    padding-top: 60px;
    margin-bottom: 65px;
  }
  .bottom .right p {
    width: 80%;
  }
  #new__slider {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  #new__slider .clock {
    padding-left: 5px;
    margin-right: 7px;
  }
  .brands__cards {
    width: 270px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  footer {
    height: fit-content;
    background-position: center center;
  }
  footer .container {
    width: 90%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .about__store {
    width: 40%;
    text-align: center;
  }
  .aboutus__title {
    margin-top: 0;
  }
  .categories .aboutus__title {
    margin-top: 77px;
  }
  .categories {
    width: 40%;
  }
  .categories .choose {
    justify-content: center;
  }
  .newsletter {
    width: 40%;
    align-items: center;
  }
  .aboutus__title {
    text-align: center;
  }
  .aboutus__txt {
    text-align: center;
  }
  form {
    width: 325px;
  }
  .new {
    min-height: 50vh;
  }
}
@media (max-width: 576px) {
  .container {
    width: 90%;
  }
  .contact .left__content div,
  .contact__txt {
    display: none;
  }
  .burger__icon {
    display: block;
  }
  header nav {
    display: none;
  }
  header .container {
    width: 90%;
  }
  .ramka {
    width: 90%;
  }
  main > p {
    width: 85%;
  }
  .top {
    height: 100%;
    flex-direction: column;
    padding-top: 20px;
  }
  .top .left {
    width: 100%;
  }
  #season__slider .clock {
    margin-right: 10px;
  }
  .top .right {
    width: 100%;
    background-position: left;
  }
  .bottom {
    flex-direction: column;
    height: 100%;
  }
  .bottom .left {
    width: 100%;
    height: 35vh;
    order: 1;
    background-position: center center;
  }
  .bottom .right {
    width: 100%;
    padding-top: 50px;
    margin-bottom: 50px;
  }
  .bottom .right p {
    width: 80%;
  }
  .new .container h1 {
    padding-top: 60px;
  }
  .new .container hr {
    margin-bottom: 40px;
  }
  .new .container {
    padding-bottom: 30px;
  }
  #new__slider {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    gap: 10px;
  }
  #new__slider .clock {
    margin-right: 10px;
  }
  #new__slider .clock img {
    width: 200px;
  }
  .brands__cards {
    flex-direction: column;
    align-items: center;
  }
  .brand a img {
    width: 100%;
  }
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
     justify-content: center;
    height: fit-content;
  }
  footer .container {
    width: 90%;
    align-items: center;
    flex-wrap: wrap;
  }
  .about__store {
    height: 100%;
    width: 100%;
    text-align: center;
  }
  .categories {
    width: 100%;
  }
  .categories .choose {
    justify-content: space-around;
  }
  .aboutus__title {
    text-align: center;
    margin: 40px 0 30px;
  }
  .newsletter {
    width: 100%;
  }
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto;
  }
  form {
    width: 325px;
  }
  input {
    padding: 10px 5px;
  }
  button {
    padding: 10px 10px;
  }
}
