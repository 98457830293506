.form3{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: rgb(231, 246, 158);
    .column{
        display: flex;
        flex-direction: column;
        align-items: center;
        input{
            padding: 10px;
            width: 250px;
            border: 1px solid black;
            border-radius: 9px;
        }
    }
    .add{
        background-color: rgb(0, 0, 0);
        color: white;
        padding: 10px;
        border-radius: 6px;
    }
}