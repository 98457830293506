* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .main {
    color: #ffffff;
    font-family: "PT Sans", sans-serif;
  }
  .nav2{
    display: flex;
    justify-content: space-between;
    gap: 45px;
  }
  .container2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
  }
  h2{
    font-size: 46px;
  }
  header {
    display: flex;
    width: 100% !important;
    height: 10vh;
    background-color: #0f0f0f;
    text-decoration: none;
    color: #fff;
  }
  header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  header a {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    text-decoration: none;
    color: #969696;
  }
  .burger {
    width: 25px;
    display: none !important;
  }
  .main {
    width: 100%;
    height: 90vh;
    background-color: #0f0f0f;
    background-image: url(../images/bgi.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .ramka {
    width: 320px;
    height: 320px;
    border: 4px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
  }
  .ramka h2{
    font-size: 50px;
  }
  .ramka img {
    margin-bottom: 8px;
  }
  .ramka hr {
    margin-bottom: 8px;
  }
  .subtitle__ramka {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
  }
  .subtitle2 {
    max-width: 720px;
    margin: 0 20px;
    text-align: center;
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
  }
  .season {
    background-color: #000000;
    color: #ffffff;
  }
  .top {
    min-height: 70vh;
    width: 100%;
    display: flex;
  }
  .top .left {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .top .left h1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 30px;
  }
  .top .left hr {
    margin: 25px 0;
  }
  #season__slider {
    max-width: 100%;
    max-height: 300px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .clock__img {
    max-width: 215px;
    background-color: #191b1c;
    padding-top: 20px;
  }
  .clock__img img {
    width: 200px;
  }
  .clock p {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }
  .clock p + p {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #d0d0d0;
  }
  .top .right {
    width: 45%;
    background-image: url(../images/Rectangle8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 100px;
  }
  .top .right h1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .top .right hr {
    margin: 30px 0;
  }
  .top .right button {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    background: none;
    padding: 5px 20px;
    border: 1px solid #ffffff;
    cursor: pointer;
  }
  .bottom {
    height: 70vh;
    display: flex;
    width: 100%;
  }
  .bottom .left {
    background-image: url(../images/Rectangle9.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 45%;
  }
  .bottom .right {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .bottom .right h1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .bottom .right h1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .bottom .right p {
    max-width: 470px;
    text-align: center;
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
  }
  .bottom .right hr {
    margin: 40px 0;
  }
  .bottom .right button {
    margin-top: 70px;
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    background: none;
    border: 1px solid #ffffff;
    padding: 5px 20px;
    cursor: pointer;
  }
  .new {
    min-height: 100vh;
    max-width: 100%;
    background-color: #000000;
    color: #ffffff;
  }
  .new .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 80px;
  }
  .new .container h1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding-top: 100px;
  }
  .new .container hr {
    margin-top: 40px;
    margin-bottom: 80px;
  }
  #new__slider {
    max-width: 90%;
    max-height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
  }
  .brands {
    background: #0f0f0f;
    color: #ffffff;
  }
  .brands .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .brands .container h1 {
    padding-top: 40px;
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .brands .container hr {
    margin: 40px 0;
  }
  .container .brands__cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 40px;
  }
  .brand {
    width: 240px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Burger{
    display: none;
  
  }
  .categories{
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: white;

  }
  @media (max-width: 1300px) {
    #season__slider {
      width: 530px;
      overflow: scroll;
      gap: 20px;
    }
    #season__slider::-webkit-scrollbar {
      width: 0;
    }
  }
  @media (max-width: 1100px) {
    #season__slider {
      width: 305px;
      gap: 10px;
    }
    header .nav2 {
      width: 60%;
    }
  }
  @media (max-width: 912px) {
    header nav {
      width: 70%;
    }
    .Burger{
      display: block !important;
    }
    .bottom .right p {
      max-width: 400px;
    }
  }
  @media (max-width: 768px) {
    #season__slider {
      margin-bottom: 40px;
    }
    .Burger{
      display: block !important;
    }
    .contact .container {
      width: 90%;
      justify-content: space-between;
    }
    .burger__icon {
      display: block;
    }
    .nav2{
      display: none !important;
    }
    header .container {
      width: 90%;
    }
    .top {
      height: 100%;
      flex-direction: column;
      padding-top: 20px;
    }
    .top .left {
      width: 100%;
    }
    .top .left hr {
      margin: 35px 0;
    }
    .top .right {
      height: 60vh;
      width: 100%;
    }
    .bottom {
      flex-direction: column;
      height: 98vh;
    }
    .bottom .left {
      width: 100%;
      height: 70vh;
      order: 1;
      background-position: center center;
    }
    .bottom .right {
      width: 100%;
      padding-top: 60px;
      margin-bottom: 65px;
    }
    .bottom .right p {
      width: 80%;
    }
    #new__slider {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: scroll;
    }
    #new__slider .clock {
      padding-left: 5px;
      margin-right: 7px;
    }
    .brands__cards {
      width: 270px;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    footer {
      height: fit-content;
      background-position: center center;
    }
    footer .container {
      width: 90%;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .about__store {
      width: 40%;
      text-align: center;
    }
    .aboutus__title {
      margin-top: 0;
    }
    .categories .aboutus__title {
      margin-top: 77px;
    }
    .categories {
      width: 40%;
    }
    .categories .choose {
      justify-content: center;
    }
    .newsletter {
      width: 40%;
      align-items: center;
    }
    .aboutus__title {
      text-align: center;
    }
    .aboutus__txt {
      text-align: center;
    }
    form {
      width: 325px;
    }
    .new {
      min-height: 50vh;
    }
  }
  @media (max-width: 576px) {
    .container {
      width: 90%;
    }
    .Burger{
      display: block !important;
    }
    .nav2{
      display: none !important;
    }
    .contact .left__content div,
    .contact__txt {
      display: none;
    }
    .burger__icon {
      display: block;
    }
    header .container {
      width: 90%;
    }
    .ramka {
      width: 90%;
    }
    main > p {
      width: 85%;
    }
    .top {
      height: 100%;
      flex-direction: column;
      padding-top: 20px;
    }
    .top .left {
      width: 100%;
    }
    #season__slider .clock {
      margin-right: 10px;
    }
    .top .right {
      width: 100%;
      background-position: left;
    }



  }
  